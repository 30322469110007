import BusinessIcon from "@mui/icons-material/Business";
import CelebrationIcon from "@mui/icons-material/Celebration";
import FunctionsIcon from "@mui/icons-material/Functions";
import SpeedIcon from "@mui/icons-material/Speed";
import {
  Box,
  CircularProgress,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Routes, useLocation, useNavigate } from "react-router-dom";
import { Navigate, Route } from "react-router-dom";
import { Hidden } from "sonobello.utilities.react.mui";

import { RoomRequest } from "../models/Room";
import useDb from "../utils/UseDb";
import useInterval from "../utils/UseInterval";
import AppContext from "./AppContext";
import LoadingRoomsBanner from "./LoadingRoomsBanner";
import CenterSettings from "./Tools/CenterSettings";
import HolidaySettings from "./Tools/HolidaySettings";
import SliderSettings from "./Tools/SliderSettings";
import VariableSettings from "./Tools/VariableSettings";

export interface AdminTool {
  setIsContentLoading: (state: boolean) => void;
}

interface DrawerListItem {
  /** The label of the tool to be displayed in the drawer toolbar. */
  text: string;
  /** The icon of the tool to be rendered in the drawer toolbar. */
  icon?: JSX.Element;
  /** The route to be used when the tool is clicked on in the drawer toolbar. */
  route: string;
}

const drawerItems: DrawerListItem[] = [
  { text: "Center Settings", icon: <BusinessIcon />, route: "/CenterSettings" },
  { text: "Holiday Settings", icon: <CelebrationIcon />, route: "/HolidaySettings" },
  { text: "Slider Settings", icon: <SpeedIcon />, route: "/SliderSettings" },
  { text: "Variable Settings", icon: <FunctionsIcon />, route: "/VariableSettings" }
];
/** Renders the administration view and its multiple child tool views. */
const Main: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigation = useNavigate();
  const location = useLocation();
  const [isContentLoading, setIsContentLoading] = useState(false);
  const { roomRequests, setRoomRequests } = useContext(AppContext);
  const roomRequestRef = useRef(roomRequests);
  useEffect(() => {
    roomRequestRef.current = roomRequests;
  }, [roomRequests]);

  // #region Update Room Requests
  const { res: getRoomRequest, setReq: setGetRoomRequest } = useDb<RoomRequest>("Get Room Request");
  useEffect(() => {
    if (!getRoomRequest) return;
    setRoomRequests(requests =>
      requests.map(request => (request.id === getRoomRequest.payload.id ? getRoomRequest.payload : request))
    );
    enqueueSnackbar(`${getRoomRequest.payload.center.name} Rooms Added`, { variant: "info" });
  }, [getRoomRequest]);
  // #endregion

  // Actions
  const onClickTool = (route: string) => {
    setIsContentLoading(true);
    navigation(route);
  };

  // On mount, begin a recurring process which polls status for unfulfilled room requests every 10 seconds
  const updateRoomRequests = useCallback(() => {
    roomRequestRef.current
      .filter(r => !r.success)
      .forEach(request =>
        // use setTimeout to eliminate state change batching
        setTimeout(
          () => setGetRoomRequest(r => r && { ...r, url: `centers/${request.centerId}/roomRequests/${request.id}` }),
          0
        )
      );
  }, []);
  useInterval(updateRoomRequests, 10000);

  return (
    <Box sx={{ height: "100%", display: "flex" }}>
      <Drawer variant="permanent">
        <Toolbar />
        <Box sx={{ overflow: "auto" }}>
          <List>
            {drawerItems.map((item, key) => (
              <ListItem
                button
                selected={location.pathname === item.route}
                key={`${key}`}
                onClick={() => onClickTool(item.route)}
              >
                {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                <ListItemText primary={item.text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ display: "flex", flexGrow: 1, height: "100%", flexDirection: "column" }}>
        <LoadingRoomsBanner />
        <Hidden sx={{ display: "flex", flexGrow: 1, height: "100%", p: 2 }} show={!isContentLoading}>
          <Routes>
            <Route path="CenterSettings" element={<CenterSettings setIsContentLoading={setIsContentLoading} />} />
            <Route path="HolidaySettings" element={<HolidaySettings setIsContentLoading={setIsContentLoading} />} />
            <Route path="SliderSettings" element={<SliderSettings setIsContentLoading={setIsContentLoading} />} />
            <Route path="VariableSettings" element={<VariableSettings setIsContentLoading={setIsContentLoading} />} />
            <Route
              path="/"
              element={
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ m: 0, width: "100%", height: "100%" }}
                >
                  <Typography variant="body1">Please select a tool from the list to get started.</Typography>
                </Grid>
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Hidden>
        <Hidden sx={{ flexGrow: 1, height: "100%" }} show={isContentLoading}>
          <Grid
            direction="column"
            container
            item
            xs
            alignItems="center"
            justifyContent="center"
            sx={{ m: 0, width: "100%", height: "100%" }}
          >
            <CircularProgress size="5rem" />
          </Grid>
        </Hidden>
      </Box>
    </Box>
  );
};

export default Main;
