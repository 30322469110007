import { PopupRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { buildLoginRequest } from "sonobello.utilities.react.msal";

import LoginCard from "./LoginCard";

const loginRequest = buildLoginRequest([`${process.env.REACT_APP_MSAL_AUTHENTICATION_SCOPE}`]);

/** Renders the login card and handles MSAL sign on. */
const Login: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { instance } = useMsal();
  const [clicked, setClicked] = useState(false);
  useEffect(() => sessionStorage.clear(), []);
  const login = () => {
    instance
      .loginPopup(loginRequest as PopupRequest)
      .then(() => enqueueSnackbar("Successfully Logged In", { variant: "success" }))
      .catch(() => {
        enqueueSnackbar("Failed to authenticate the user. Please Try again.", { variant: "error" });
        sessionStorage.clear();
      })
      .finally(() => setClicked(false));
    setClicked(true);
  };
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
      <LoginCard>
        <Grid container direction="column" spacing={2} alignItems="center">
          <Grid item>
            <LoadingButton startIcon={<ExitToAppIcon />} onClick={login} loading={clicked} variant="outlined">
              Log In
            </LoadingButton>
          </Grid>
        </Grid>
      </LoginCard>
    </Box>
  );
};

export default Login;
