import { Chip, Grid, Paper, Typography } from "@mui/material";
import React, { ReactNode } from "react";

// import Logo from "../../assets/sonobello-textel-192.png";

const LoginCard: React.FC<{ children?: ReactNode }> = ({ children }: { children?: ReactNode }) => {
  return (
    <Paper sx={{ height: "15rem", width: "30rem", padding: "1rem 2rem 1rem 2rem" }} elevation={3}>
      <Grid
        container
        direction="column"
        alignItems="center"
        wrap="nowrap"
        justifyContent="space-between"
        rowSpacing={3}
        sx={{ height: "100%" }}
      >
        <Grid container item justifyContent="center" alignItems="center" columnSpacing={1} wrap="nowrap">
          {/* <Grid item>
            <img style={{ width: "6rem" }} src={Logo} alt="test icon" />
          </Grid> */}
          <Grid item>
            <Typography variant="h4">Double Booker Configuration</Typography>
          </Grid>
        </Grid>
        <Grid item container xs alignItems="center">
          {children}
        </Grid>
        <Grid item container justifyContent="center">
          <Chip
            label={`${process.env.REACT_APP_ENVNAME ? `${process.env.REACT_APP_ENVNAME} - ` : ""}v${
              process.env.REACT_APP_VERSION_NO
            }`}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default LoginCard;
