/* eslint-disable @typescript-eslint/no-unused-vars */
import { ComparatorFn } from "sonobello.utilities.react.mui";
import { AnalyticsType } from "./AnalyticsTypes";
import { Variable } from "./Variable";

/** A category associated with a categorical {@link Variable}. See {@link AnalyticsType.Categorical} */
export class Category {
  /** The unique identifier of the object. */
  id!: number;

  /** The {@link Variable.id} associated with the category. */
  analyticsVariableId!: number;
  /** The value of the category to be used in appointment evaluation. */
  coefficient!: number;
  /** The plain text name of the category. */
  name!: string;

  static AlphaComparator: ComparatorFn<Category> = (c1, c2) => (c1.name > c2.name ? 1 : -1);
}
