import { ComparatorFn } from "sonobello.utilities.react.mui";

import { EnhancedHoliday, Holiday } from "./Holiday";
import { Room, RoomTypeId } from "./Room";
import { Slider } from "./Slider";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { TimeZone } from "./TimeZone";

export class Center {
  /** The unique identifier for the center. */
  id!: number;
  /** The plain text name of the center. */
  name!: string;
  /** The {@link Holiday} which which the center was returned. */
  holiday!: Holiday;
  /** The {@link Room.id} of the primary room for this center. */
  primaryRoomId?: number;
  /** The {@link Room | Rooms} located in this center.*/
  rooms!: Room[];
  /** The {@link TimeZone.id} for this center. */
  timeZoneId?: number;

  /** A comparator which sorts centers by name alphabetically. */
  static nameComparator: ComparatorFn<Center> = (c1, c2) => {
    if (c1.name > c2.name) return 1;
    else return -1;
  };

  /** Determine if the given center meets the minimum requirements in order for the double booker to run for it.
   * @remarks This means it requires configured rooms and days in advance to book.
   */
  static isDisabled = (c1: Center): boolean => {
    if (
      // require that it has a primary room
      !c1.rooms.some(r => r.roomTypeId === RoomTypeId.PRIMARY) ||
      // require that it has at least one aged room
      !c1.rooms.some(r => r.roomTypeId === RoomTypeId.AGED)
    )
      return true;
    return false;
  };

  constructor(center: Center) {
    this.id = center.id;
    this.name = center.name;
    this.holiday = center.holiday;
    this.primaryRoomId = center.primaryRoomId;
    this.rooms = center.rooms;
    this.timeZoneId = center.timeZoneId;
  }
}

/** An enhanced {@link Center} with enhanced child props. */
export class EnhancedCenter extends Center {
  /** The {@link EnhancedHoliday} that the center was returned with. */
  holiday!: EnhancedHoliday;

  constructor(center: Center, slider?: Slider) {
    super(center);
    this.holiday = new EnhancedHoliday(center.holiday, slider);
  }
}

/** A model representing a center which has not been set up in the double booker. */
export interface UnmappedCenter {
  /** The unique identifier of the center in the Identity database. */
  locationId: number;
  /** The plain text name of the center. */
  name: string;
  /** The plain text {@link TimeZone.name} of the center. */
  timeZone: string;
}
