import AddCircleIcon from "@mui/icons-material/AddCircle";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  ListItemIcon,
  Popover,
  TextField,
  Typography
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import {
  AdminFormLabel,
  SelectList,
  SelectListItem,
  SelectListItemProps,
  ValidationButton,
  ValidationButtonTest
} from "sonobello.utilities.react.mui";

import { EnhancedHoliday, Holiday, PostHolidayRequest } from "../../models/Holiday";
import useDb from "../../utils/UseDb";
import { AdminTool } from "../Main";
import ToolWrapper from "../ToolWrapper";

const toolReadme = `
This tool will allow for the user to create and delete holidays.

Holidays allow for time-period specific center settings. When evaluating appointments for double booking, the double
booker first looks at any holidays may be applicable to the considered appointment. If one is found, the holiday
specific settings are used in the evaluation, including the sensitivity coefficient and days in advance to book.
`;
const holidaysReadme = `
Holidays and their detailed configuration are visible here. You may not modify existing holidays, only delete them.
`;
const configReadme = `
A name, start, and end dates are all required to create a holiday.

The holiday name cannot have an identical name to a previous live (or deleted) holiday.

Both start and end dates must be equal or in chronological order and cannot be in the past.
`;

const validationTests: ValidationButtonTest<EnhancedHoliday | undefined>[] = [
  h => !h?.name && "Name is required.",
  h => !h?.startDate && "Start Date is required.",
  h => !h?.endDate && "End Date is required.",
  h => h?.startDateTime && h.startDateTime < DateTime.now().startOf("day") && "Start Date must not be in the past.",
  h =>
    h?.startDateTime &&
    h?.endDateTime &&
    h.startDateTime > h.endDateTime &&
    "Start date must be before or equal to end date."
];

const defaultHolidayId = Number(process.env.REACT_APP_Default_HOLIDAY_ID);
if (isNaN(defaultHolidayId)) throw "Configuration for the default holiday id is missing or default.";

const HolidaySettings: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();

  const [holiday, setHoliday] = useState<EnhancedHoliday>();
  const [holidays, setHolidays] = useState<EnhancedHoliday[]>([]);
  const [deleteAnchor, setDeleteAnchor] = useState<HTMLElement>();

  const { res: getHolidays, loading: loadingHolidays } = useDb<Holiday[]>("Get Holidays", { url: "holidays" });
  useEffect(() => setHolidays(getHolidays?.payload.map(h => new EnhancedHoliday(h)) || []), [getHolidays]);

  // show spinner while loading
  useEffect(() => setIsContentLoading(loadingHolidays), [loadingHolidays]);

  // #region Update Center
  const {
    res: updateHoliday,
    loading: updateLoading,
    setReq: setUpdate
  } = useDb<Holiday, Required<Holiday> | PostHolidayRequest, { id: number; name: string }>("Update Holiday");
  useEffect(() => {
    if (!updateHoliday) return;
    const isDeletion = updateHoliday.request.method === "delete";
    enqueueSnackbar(`Holiday ${isDeletion ? "Deleted" : "Created"}`, { variant: "success" });
    setHoliday(undefined);
    setHolidays(oldHolidays => {
      const id = isDeletion ? updateHoliday.request.custom.id : updateHoliday.payload.id;
      const index = oldHolidays.findIndex(c => c.id === id);
      if (index !== -1) {
        if (isDeletion) oldHolidays.splice(index, 1);
        else oldHolidays[index] = new EnhancedHoliday(updateHoliday.payload);
      } else oldHolidays.push(new EnhancedHoliday(updateHoliday.payload));
      return [...oldHolidays.sort(EnhancedHoliday.chronoComparator)];
    });
  }, [updateHoliday]);
  // #endregion

  // Actions
  const onConfirmDelete = () => {
    setDeleteAnchor(undefined);
    setUpdate(
      r =>
        r && {
          ...r,
          url: `holidays/${holiday!.id}`,
          method: "delete",
          payload: undefined,
          custom: { ...r.custom, id: holiday!.id }
        }
    );
  };

  return (
    <ToolWrapper title="Holiday Settings" readme={toolReadme} sx={{ minWidth: "55rem" }}>
      <Grid container direction="column" alignItems="center" spacing={2} sx={{ height: "100%" }}>
        <Grid container item xs spacing={2} wrap="nowrap">
          <Grid item xs>
            <Grid container direction="column" spacing={2} wrap="nowrap" sx={{ height: "100%" }}>
              <Grid item container justifyContent="space-between">
                <Grid item>
                  <AdminFormLabel label="Holidays" readme={holidaysReadme} />
                </Grid>
              </Grid>
              <Grid item>
                <SelectList
                  items={holidays}
                  value={holiday || null}
                  disabled={loadingHolidays}
                  isItemEqualToValue={(i1, i2) => i1.id === i2.id}
                  onChange={newValue => setHoliday(newValue || undefined)}
                  renderItem={HolidayItem}
                  sx={{ minWidth: "20rem" }}
                />
              </Grid>
              <Grid item container justifyContent="space-around" spacing={1}>
                <Grid item>
                  <Button
                    disabled={updateLoading}
                    onClick={() => setHoliday({ name: "", id: NaN })}
                    startIcon={<AddCircleIcon />}
                  >
                    Create New
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    disabled={updateLoading || !holiday?.id || holiday.id === defaultHolidayId}
                    startIcon={<DeleteIcon />}
                    color="error"
                    onClick={e => setDeleteAnchor(e.currentTarget)}
                  >
                    Delete
                  </Button>
                  <Popover
                    open={Boolean(deleteAnchor)}
                    anchorEl={deleteAnchor}
                    onClose={() => setDeleteAnchor(undefined)}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                  >
                    <Grid container direction="column" alignItems="center" spacing={2} sx={{ p: 2 }}>
                      <Grid item>
                        <Typography variant="h6">{`Are you sure you want to delete ${holiday?.name}?`}</Typography>
                      </Grid>
                      <Grid item>
                        <Button onClick={onConfirmDelete}>Confirm</Button>
                      </Grid>
                    </Grid>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={2} sx={{ height: "100%" }}>
              <Grid item>
                <AdminFormLabel
                  label={`Configuration ${holiday ? ` for ${holiday.id ? holiday.name : "New Holiday"}` : ""}`}
                  readme={configReadme}
                />
              </Grid>
              <Grid item>
                <TextField
                  label="Name"
                  fullWidth
                  value={holiday?.name || ""}
                  disabled={!holiday || !isNaN(holiday.id)}
                  onChange={e => setHoliday(h => h && { ...h, name: e.target.value })}
                />
              </Grid>
              <Grid item>
                <DatePicker
                  renderInput={props => <TextField {...props} />}
                  label="Start Date"
                  disabled={!holiday || !isNaN(holiday.id)}
                  value={holiday?.startDateTime || null}
                  onChange={d =>
                    setHoliday(h => h && { ...h, startDateTime: d || undefined, startDate: d?.toISODate() })
                  }
                  mask="__/__/____"
                />
              </Grid>
              <Grid item>
                <DatePicker
                  renderInput={props => <TextField {...props} />}
                  label="End Date"
                  disabled={!holiday || !isNaN(holiday.id)}
                  value={holiday?.endDateTime || null}
                  onChange={d => setHoliday(h => h && { ...h, endDateTime: d || undefined, endDate: d?.toISODate() })}
                  mask="__/__/____"
                />
              </Grid>
              <Grid item container justifyContent="space-around" spacing={1}>
                <Collapse in={holiday && !holiday.id}>
                  {updateLoading ? (
                    <CircularProgress />
                  ) : (
                    <Grid item>
                      <ValidationButton
                        validationTarget={holiday}
                        tests={validationTests}
                        disabled={!holiday || holiday.id === defaultHolidayId}
                        startIcon={<CheckIcon />}
                        onClick={() => setUpdate(r => r && { ...r, method: "post", payload: holiday, url: "holidays" })}
                      >
                        Save Changes
                      </ValidationButton>
                    </Grid>
                  )}
                </Collapse>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ToolWrapper>
  );
};

const HolidayItem: React.FC<SelectListItemProps<EnhancedHoliday>> = ({ item: holiday, ...rest }) => (
  <SelectListItem label={holiday => holiday.name} item={holiday} {...rest}>
    <ListItemIcon>
      {holiday.startDateTime && holiday.endDateTime && (
        <Chip
          size="small"
          label={
            holiday.startDateTime.equals(holiday.endDateTime)
              ? holiday.startDateTime.toLocaleString(DateTime.DATE_MED)
              : `${holiday.startDateTime.toLocaleString(DateTime.DATE_MED)} - ${holiday.endDateTime.toLocaleString(
                  DateTime.DATE_MED
                )}`
          }
        />
      )}
    </ListItemIcon>
  </SelectListItem>
);

export default HolidaySettings;
