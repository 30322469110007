/** The analytical type of a double booker evaluation variable. */
export interface AnalyticsType {
  /** The unique identifier of the type. */
  id: number;
  /** The associated name of the type. */
  name: AnalyticsTypeName;
}

export enum AnalyticsTypeName {
  /** Categorical variables have a default coefficient and distinct coefficients when the variable value matches
   * an established category name. */
  Categorical = "Categorical",
  /** Continuous variables have a specified coefficient value that is unchanging. */
  Continuous = "Continuous",
  /** The intercept variable is a unique variable with its own type. The intercept variable is essentially the offset
   * from the origin point in the appointment evaluation. */
  Intercept = "Intercept"
}
