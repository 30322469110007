import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Grid, IconButton, Paper, Popover, Theme, Tooltip, Typography } from "@mui/material";
import { SxProps } from "@mui/system";
import React, { PropsWithChildren, useState } from "react";
import { MuiReactMarkdown } from "sonobello.utilities.react.mui";

export interface ToolWrapperProps {
  /** The label of the tool to be displayed at the top of the view. */
  title: string;
  /** The markdown string to be rendered in the tool documentation popover. */
  readme: string;
  /** Style props passed to the `<Paper />` container of the element children. */
  sx?: SxProps<Theme>;
}

const ToolWrapper: React.FC<PropsWithChildren<ToolWrapperProps>> = ({
  readme,
  sx,
  title,
  children
}: PropsWithChildren<ToolWrapperProps>) => {
  const [descriptionAnchor, setDescriptionAnchor] = useState<HTMLElement>();

  return (
    <Grid container direction="column" alignItems="center" sx={{ height: "100%", m: 0 }} wrap="nowrap">
      <Grid item container>
        <Grid item xs>
          <Typography variant="h5" align="center">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip title="More Info">
            <IconButton onClick={e => setDescriptionAnchor(e.currentTarget)}>
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs
        justifyContent="center"
        alignItems="start"
        sx={{ width: "100%", overflow: "hidden", pb: 0.5 }}
      >
        <Paper elevation={3} sx={{ maxWidth: "70rem", p: 2, height: "100%", ...sx }}>
          {children}
        </Paper>
      </Grid>
      <Popover
        open={Boolean(descriptionAnchor)}
        anchorEl={descriptionAnchor}
        onClose={() => setDescriptionAnchor(undefined)}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Grid container spacing={2} sx={{ m: 0, p: 2, maxWidth: "40rem" }}>
          <MuiReactMarkdown>{readme}</MuiReactMarkdown>
        </Grid>
      </Popover>
    </Grid>
  );
};

export default ToolWrapper;
