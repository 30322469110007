import { AnalyticsType } from "./AnalyticsTypes";
import { Category } from "./Category";

/** A factor which is used in the analysis of an appointment for consideration of double-booking. */
export class Variable {
  /** The unique identifier of the object. */
  id: number;
  /** Flag indicating if the variable is utilized in appointment evaluations. */
  active: boolean;
  /** Linked categories for categorical variables. See {@link AnalyticsTypeName.Categorical}. */
  categories: Category[];
  /** The coefficient value used in calculations when the property concerning the variable has a value. */
  coefficient: number;
  /** The coefficient value used in calculations when the property concerning the variable does not have a value. */
  defaultValue: number;
  /** The plain text name of the variable. */
  name: string;
  /** The optional id of the variable which borrows the coefficient of this variable when this variable has a value. */
  overridesId?: number;
  /** The {@link AnalyticsType.id} which the variable is associated with. */
  typeId: number;

  constructor(variable: Variable) {
    this.id = variable.id;
    this.active = variable.active;
    this.categories = [...variable.categories];
    this.coefficient = variable.coefficient;
    this.defaultValue = variable.defaultValue;
    this.name = variable.name;
    this.overridesId = variable.overridesId;
    this.typeId = variable.typeId;
  }

  /** Returns a flag indicating if there is a child prop difference between the two provided variables. */
  static isChange = (var1: Variable, var2: Variable) => {
    return (
      var1.active != var2.active ||
      var1.coefficient !== var2.coefficient ||
      var1.defaultValue !== var2.defaultValue ||
      var1.overridesId !== var2.overridesId ||
      var1.categories.some(
        c1 => !var2.categories.some(c2 => c1.name === c2.name && c1.id === c2.id && c1.coefficient != c2.coefficient)
      )
    );
  };
}

/** A variable enhanced with the referred child objects. */
export class EnhancedVariable extends Variable {
  /** The full object referenced by {@link EnhancedVariable.typeId}. */
  analyticsType: AnalyticsType;
  /** The full object referenced by {@link EnhancedVariable.overridesId}. */
  overrideVariable?: Variable;

  constructor(variable: Variable, analyticsTypes: AnalyticsType[], overrideVariable?: Variable) {
    super(variable);
    const analyticsType = analyticsTypes.find(at => at.id === variable.typeId);
    if (!analyticsType) throw "Analytics type not found.";
    this.analyticsType = analyticsType;
    this.overrideVariable = overrideVariable && new Variable(overrideVariable);
  }
}
