import { Mark } from "@mui/base";
import { ComparatorFn } from "sonobello.utilities.react.mui";

import { Slider } from "../models/Slider";

/** An object representing the configuration for an MUI slider component derived from a set of {@link Slider} models. */
export class SliderConfig {
  /** The minimum value (lower bound) of the slider to be rendered. */
  min: number;
  /** The maximum value (upper bound) of the slider to be rendered. */
  max: number;
  /** The models which derive this configuration. */
  sliders: Slider[];
  /** The MUI configuration objects which configure the MUI Slider component. */
  marks: Mark[];

  constructor(sliders: Slider[]) {
    this.min = sliders.reduce((max, { value }) => (max < value ? max : value), sliders[0].value);
    this.max = sliders.reduce((max, { value }) => (max > value ? max : value), sliders[0].value);
    this.marks = sliders.map(s => ({ value: s.value, label: String(Math.round(s.value * 100)) }));
    this.sliders = sliders;
  }

  /** A comparator function which will order sliders in order of ascending value of their value prop. */
  static Comparator: ComparatorFn<Slider> = (s1, s2) => {
    if (s1.value > s2.value) return 1;
    else return -1;
  };

  /** Build the class object from an existing config and a new set of slider values. */
  static fromValues = (values: number[], config: SliderConfig): SliderConfig => {
    if (values.length !== config.sliders.length) throw "values and sliders must have equal length.";
    const sortedValues = values.sort();
    const sliders = config.sliders.map((s, index) => ({ ...s, value: sortedValues[index] }));
    return new SliderConfig(sliders);
  };

  /** Returns a flag indicating if there are value differences in the sliders for the two given configs. */
  static isChange = (s1: SliderConfig, s2: SliderConfig) => {
    return s1.sliders.some(s => !s2.sliders.some(v => v.value === s.value));
  };
}
