// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ClientRequestArgs } from "http";

import { Center } from "./Center";

/** A data representation of a physical room in a center.  */
export interface Room {
  id: string;
  /** The unique identifier of the {@link Center} that this room is located at. */
  centerId: number;
  /** The plain text name of the room. */
  name: string;
  /** The category of the room. See {@link RoomTypeId}. */
  roomTypeId?: RoomTypeId;
  /** The external id of the room in Zenoti. */
  zenotiRoomId: string;
}

/** The category into which the room falls. */
export enum RoomTypeId {
  "PRIMARY" = 1,
  "SECONDARY" = 2,
  "AGED" = 3
}

/** The model representing a a request for rooms for a particular center. */
export interface RoomRequest {
  /** The unique identifier of the request. */
  id: string;
  /** The {@link Center} for which the rooms were requested. */
  center: Center;
  /** The {@link Center.id} for which the rooms were requested. */
  centerId: number;
  /** The response message from the service which fulfills the request. */
  responseMessage: string;
  /** The flag indicating success of the request fulfillment. */
  success?: boolean;
}
