import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import {
  Autocomplete,
  Button,
  Chip,
  CircularProgress,
  Grid,
  ListItemIcon,
  Slider as MuiSlider,
  TextField,
  Typography
} from "@mui/material";
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import React, { HTMLAttributes, useContext, useEffect, useRef, useState } from "react";
import {
  AdminFormLabel,
  SelectList,
  SelectListItem,
  SelectListItemProps,
  ValidatedTextField
} from "sonobello.utilities.react.mui";

import { Center, EnhancedCenter, UnmappedCenter } from "../../models/Center";
import { EnhancedHoliday, Holiday } from "../../models/Holiday";
import { Room, RoomRequest, RoomTypeId } from "../../models/Room";
import { Sensitivity } from "../../models/Sensitivity";
import { Slider } from "../../models/Slider";
import { TimeZone } from "../../models/TimeZone";
import { SliderConfig } from "../../types/SliderConfig";
import useDb from "../../utils/UseDb";
import AppContext from "../AppContext";
import { AdminTool } from "../Main";
import ToolWrapper from "../ToolWrapper";

/** An extension of `Room` so that we can associate which list the item is being rendered in. */
interface RoomListItem extends Room {
  inListType: RoomTypeId;
}

interface AddUnmappedCenter {
  currentHolidayId: number;
  identityLocationId: number;
  name: string;
  timeZoneId: number;
}

const toolReadme = `
The Center Settings tool allows you to configure how double booking evaluation considers individual centers.

**Note that newly added centers have their settings initialized to match the Default center.**
`;
const holidayReadme = `
The settings shown in the tool are for the currently selected holiday. The default holiday settings are used in double-booking evaluation for any date which does not fall directly under another specified holiday.
`;
const centersReadme = `
Configured centers are listed here. If sufficient configuration exists to evaluate a center for double booking then the sensitivity is listed. Otherwise, the center is labeled as \`disabled\`.

You may add new center configurations in the below drop-down menu.
`;
const agedRoomReadme = `
For any double booking evaluation the D-Aged room is the first room considered as a destination target.

Any appointment that is evaluated to have less likelihood of attendance than the center's sensitivity value will be moved here if space on the schedule allows for it.
`;
const secondaryRoomsReadme = `
For any double booking evaluation the secondary room is the second room considered considered as a destination target, following consideration of the d-aged room.

If the appointment is not moved to D-Aged, the secondary room is considered and the appointment will be moved here regardless of sensitivity if there is space available on the schedule.
`;
const sensitivityReadme = `
  The sensitivity setting adjusts how likely a center is to double book for an appointment. 
  The selection breakpoints for the slider can be configured in the Slider Settings tool.

  Ex: If a center has a sensitivity of 75%, then all guests determined to have a less than 75% chance of showing will be moved to D - Aged.
`;
const daysAdvanceReadme = `
  Each week day has a number of days in advance to consider double booking.

  If Sunday is set to 5 days, Sundays on the calendar that are more than 5 days in the future will not be considered
  for double booking in this center.
`;

const advanceDaysConfig: {
  key: keyof Pick<
    Sensitivity,
    | "mondayAdvance"
    | "tuesdayAdvance"
    | "wednesdayAdvance"
    | "thursdayAdvance"
    | "fridayAdvance"
    | "saturdayAdvance"
    | "sundayAdvance"
  >;
  label: string;
}[] = [
  { key: "sundayAdvance", label: "Sun" },
  { key: "mondayAdvance", label: "Mon" },
  { key: "tuesdayAdvance", label: "Tue" },
  { key: "wednesdayAdvance", label: "Wed" },
  { key: "thursdayAdvance", label: "Thu" },
  { key: "fridayAdvance", label: "Fri" },
  { key: "saturdayAdvance", label: "Sat" }
];

const defaultHolidayId = Number(process.env.REACT_APP_Default_HOLIDAY_ID);
if (isNaN(defaultHolidayId)) throw "Configuration for the default holiday id is missing or default.";

const CenterSettings: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const { roomRequests, setRoomRequests } = useContext(AppContext);
  const [holiday, setHoliday] = useState<EnhancedHoliday>();
  const [holidays, setHolidays] = useState<EnhancedHoliday[]>([]);
  const [unmappedCenters, setUnmappedCenters] = useState<UnmappedCenter[]>([]);
  const [unmappedCenter, setUnmappedCenter] = useState<UnmappedCenter>();
  const [centers, setCenters] = useState<EnhancedCenter[]>([]);
  const [centersFilter, setCentersFilter] = useState("");
  const [selectedCenter, setSelectedCenter] = useState<EnhancedCenter>();
  const [sliderConfig, setSliderConfig] = useState<SliderConfig>();
  const slidersRef = useRef<Slider[]>([]);

  // Get Models
  const { res: getHolidays } = useDb<Holiday[]>("Get Holidays", { url: "holidays" });
  useEffect(() => setHolidays((getHolidays?.payload || []).map(h => new EnhancedHoliday(h))), [getHolidays]);
  const { res: getSliders, loading: loadingSliders } = useDb<Slider[]>("Get Sliders", { url: "slider" });
  const {
    res: getCenters,
    loading: loadingCenters,
    setReq: setCentersReq
  } = useDb<Center[]>("Get Centers", { url: "centers/holidays/-1" });
  const {
    res: getUnmapped,
    loading: loadingUnmapped,
    setReq: setUnmappedReq
  } = useDb<UnmappedCenter[]>("Get Unmapped", { url: "centers/unmapped" });
  const { res: getTimeZones, loading: loadingTimeZones } = useDb<TimeZone[]>("Get Timezones", { url: "timezones" });

  useEffect(() => {
    if (!getCenters || !getSliders) return;
    const newCenters = getCenters.payload.sort(Center.nameComparator).map(
      c =>
        new EnhancedCenter(
          c,
          getSliders.payload.find(s => s.id === c.holiday.sensitivity!.sliderPositionId)
        )
    );
    setCenters(newCenters);
    setSelectedCenter(c => c && { ...newCenters.find(c2 => c2.id === c.id)! });
  }, [getCenters, getSliders]);
  useEffect(() => {
    if (getSliders) slidersRef.current = getSliders.payload;
  }, [getSliders]);
  useEffect(() => getUnmapped && setUnmappedCenters(getUnmapped.payload), [getUnmapped]);
  useEffect(() => {
    if (!getSliders?.payload.length) return;
    setSliderConfig(new SliderConfig(getSliders.payload));
    setHoliday(h => (h ? h : holidays.find(h => h.id === defaultHolidayId)));
  }, [getSliders, holidays]);
  // show spinner while loading
  useEffect(
    () => setIsContentLoading(!holidays || loadingSliders || loadingUnmapped || loadingTimeZones || !centers.length),
    [holidays, loadingSliders, loadingUnmapped, loadingTimeZones, centers]
  );

  // #region Update Center
  const {
    res: updateCenter,
    loading: updateLoading,
    setReq: setUpdateRequest
  } = useDb<Center, Center>("Update Center", { method: "put" });
  useEffect(() => {
    if (!updateCenter) return;
    enqueueSnackbar("Campaign Type Updated", { variant: "success" });
    const newCenter = {
      ...updateCenter.payload,
      holiday: {
        ...updateCenter.payload.holiday,
        sensitivity: {
          ...updateCenter.payload.holiday.sensitivity!,
          slider: slidersRef.current.find(s => s.id === updateCenter.payload.holiday.sensitivity!.sliderPositionId)!
        }
      }
    };
    setSelectedCenter(newCenter);
    setCenters(oldCenters => {
      const index = oldCenters!.findIndex(c => c.id === updateCenter.payload.id);
      oldCenters[index] = { ...newCenter };
      return [...oldCenters];
    });
  }, [updateCenter]);
  // #endregion

  // #region Register Unmapped
  const {
    res: postUnmapped,
    loading: loadingPostUnmapped,
    setReq: setUpdateUnmapped
  } = useDb<Center, AddUnmappedCenter>("Add Unmapped Center", { method: "post" });
  const { res: postRoomRequest, setReq: setPostRoomRequest } = useDb<RoomRequest, unknown>("Post Room Request", {
    method: "post"
  });
  useEffect(() => postRoomRequest && setRoomRequests(r => [...r, postRoomRequest.payload]), [postRoomRequest]);
  useEffect(() => {
    if (!postUnmapped) return;
    enqueueSnackbar("Center Successfully Added", { variant: "success" });
    setCenters(oldCenters =>
      [
        ...oldCenters,
        {
          ...postUnmapped.payload,
          holiday: {
            ...postUnmapped.payload.holiday,
            sensitivity: {
              ...postUnmapped.payload.holiday.sensitivity!,
              slider: slidersRef.current.find(s => s.id === postUnmapped.payload.holiday.sensitivity!.sliderPositionId)!
            }
          }
        }
      ].sort(Center.nameComparator)
    );
    setPostRoomRequest(r => r && { ...r, url: `centers/${postUnmapped.payload.id}/roomRequests`, payload: {} });
    setUnmappedCenter(undefined);
    setUnmappedReq(r => r && { ...r });
  }, [postUnmapped]);
  // #endregion

  // wait until all room requests are fulfilled, then clear the requests and refresh centers
  useEffect(() => {
    if (!roomRequests.length || roomRequests.some(request => !request.success)) return;
    setRoomRequests(requests => requests.filter(r => !r.success));
    setCentersReq(r => r && { ...r });
  }, [roomRequests]);

  // Actions
  const onHolidayChange = (holiday: EnhancedHoliday) => {
    setHoliday(holiday);
    setCentersReq(r => r && { ...r, url: `centers/holidays/${holiday.id}` });
  };
  const updateRoomSelection = (selectedRooms: RoomListItem[], roomTypeId: RoomTypeId) =>
    setSelectedCenter(
      c =>
        c && {
          ...c,
          rooms: c.rooms.map(r =>
            selectedRooms.some(r2 => r2.id === r.id)
              ? // assign the room type to new items
                { ...r, roomTypeId }
              : // clear room type for deselected items
              r.roomTypeId === roomTypeId
              ? { ...r, roomTypeId: undefined }
              : r
          )
        }
    );
  const onAddUnmapped = () =>
    setUpdateUnmapped(
      r =>
        r && {
          ...r,
          url: "centers",
          payload: {
            currentHolidayId: holiday!.id,
            identityLocationId: unmappedCenter!.locationId,
            name: unmappedCenter!.name,
            timeZoneId: getTimeZones!.payload.find(tz => tz.name === unmappedCenter?.timeZone)!.id
          }
        }
    );
  const updateSensitivity = (value: number, dayKey: keyof Sensitivity) =>
    setSelectedCenter(
      c =>
        c?.holiday.sensitivity && {
          ...c,
          holiday: { ...c.holiday, sensitivity: { ...c.holiday.sensitivity, [dayKey]: value } }
        }
    );
  const primaryRoomValue = selectedCenter?.rooms.find(r => r.roomTypeId === RoomTypeId.PRIMARY);
  const onSliderChange = (value: number) => {
    const slider = sliderConfig?.sliders.find(s => s.value === value);
    if (!slider) return;
    setSelectedCenter(
      c =>
        c && {
          ...c,
          holiday: {
            ...c.holiday,
            sensitivity: { ...c.holiday.sensitivity!, sliderPositionId: slider.id, slider }
          }
        }
    );
  };
  return (
    <ToolWrapper title="Center Settings" readme={toolReadme} sx={{ minWidth: "74rem" }}>
      <Grid container item xs spacing={3} wrap="nowrap" sx={{ height: "100%" }}>
        <Grid item xs>
          <Grid container direction="column" spacing={2} wrap="nowrap" sx={{ height: "100%" }}>
            <Grid item>
              <AdminFormLabel label="Holiday" readme={holidayReadme} />
              {holiday && (
                <Autocomplete
                  isOptionEqualToValue={(h1, h2) => h1.id === h2.id}
                  options={holidays}
                  disabled={updateLoading}
                  renderInput={params => <TextField {...params} placeholder="Holiday..." />}
                  getOptionLabel={holiday =>
                    `${holiday.name}${holiday.id !== defaultHolidayId ? ` - ${holidayDateTemplate(holiday)}` : ""}`
                  }
                  renderOption={(props, holiday) => <HolidayOption props={props} holiday={holiday} />}
                  value={holiday}
                  onChange={(_, holiday) => onHolidayChange(holiday)}
                  disableClearable
                />
              )}
            </Grid>
            <Grid item container justifyContent="space-between">
              <Grid item>
                <AdminFormLabel label="Centers" readme={centersReadme} />
              </Grid>
              <Grid item>
                <TextField
                  placeholder="Filter by name..."
                  value={centersFilter}
                  onChange={e => setCentersFilter(e.target.value.toLowerCase())}
                />
              </Grid>
            </Grid>
            <Grid item xs sx={{ overflow: "auto" }}>
              <SelectList
                items={centersFilter ? centers.filter(c => c.name.toLowerCase().includes(centersFilter)) : centers}
                value={selectedCenter || null}
                disabled={loadingCenters}
                isItemEqualToValue={(i1, i2) => i1.id === i2.id}
                onChange={newValue => setSelectedCenter(newValue || undefined)}
                renderItem={CenterItem}
                sx={{ minWidth: "20rem" }}
              />
            </Grid>
            <Grid item container alignItems="center" spacing={1}>
              <Grid item xs>
                <Autocomplete
                  fullWidth
                  isOptionEqualToValue={(c1, c2) => c1.name === c2.name}
                  options={unmappedCenters}
                  disabled={updateLoading}
                  renderInput={params => <TextField {...params} label="Add Center" />}
                  getOptionLabel={unmapped => unmapped.name}
                  value={unmappedCenter || null}
                  onChange={(_, unmapped) => setUnmappedCenter(unmapped || undefined)}
                />
              </Grid>
              <Grid item>
                {loadingPostUnmapped ? (
                  <CircularProgress />
                ) : (
                  <Button disabled={!unmappedCenter || loadingUnmapped} onClick={onAddUnmapped}>
                    Add
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={2} sx={{ height: "100%" }}>
            <Grid item>
              <Typography variant="h6">{`Configuration ${
                selectedCenter ? ` for ${selectedCenter.name}` : ""
              }`}</Typography>
            </Grid>
            <Grid item sx={{ pr: 2 }}>
              <AdminFormLabel label="Sensitivity (%)" readme={sensitivityReadme} />
              {sliderConfig && (
                <MuiSlider
                  disabled={!selectedCenter || loadingCenters}
                  value={
                    getSliders?.payload.find(s => s.id === selectedCenter?.holiday.sensitivity?.sliderPositionId)
                      ?.value || 0
                  }
                  step={null}
                  min={sliderConfig.min}
                  max={sliderConfig.max}
                  marks={sliderConfig.marks}
                  onChange={(_, newValue) => onSliderChange(newValue as number)}
                />
              )}
            </Grid>
            <Grid item>
              <AdminFormLabel label="Days In Advance To Double Book" readme={daysAdvanceReadme} />
              <Grid container justifyContent="space-between" spacing={1} sx={{ mt: 1 }}>
                {advanceDaysConfig.map(({ label, key }, index) => (
                  <Grid item key={index}>
                    <ValidatedTextField
                      disabled={!selectedCenter || loadingCenters}
                      label={label}
                      value={selectedCenter?.holiday.sensitivity![key]}
                      onChange={value => updateSensitivity(value ? Number(value) : 0, key)}
                      rejectInput={value => !/^([0-9]{0,})$/.test(value)}
                      helperText={null}
                      sx={{ width: "70px" }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid item xs>
              <Grid container wrap="nowrap" spacing={0.5} sx={{ height: "100%" }}>
                <Grid item container direction="column" xs>
                  <Grid item>
                    <AdminFormLabel
                      label="Primary Room"
                      readme={
                        "The primary room's appointments are considered for double booking and can be moved to the d-aged or secondary rooms."
                      }
                    />
                  </Grid>
                  <Grid item xs sx={{ overflow: "auto" }}>
                    <SelectList
                      items={selectedCenter?.rooms.map<RoomListItem>(r => ({ ...r, inListType: RoomTypeId.PRIMARY }))}
                      value={primaryRoomValue ? { ...primaryRoomValue, inListType: RoomTypeId.PRIMARY } : null}
                      disabled={!selectedCenter || loadingCenters}
                      isItemEqualToValue={(i1, i2) => i1.id === i2.id}
                      onChange={newValue => updateRoomSelection(newValue ? [newValue] : [], RoomTypeId.PRIMARY)}
                      renderItem={RoomItem}
                      sx={{ minWidth: "15rem" }}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column">
                  <Grid item>
                    <AdminFormLabel
                      label={`Secondary Rooms (${
                        selectedCenter?.rooms.filter(r => r.roomTypeId === RoomTypeId.SECONDARY).length || 0
                      })`}
                      readme={secondaryRoomsReadme}
                    />
                  </Grid>
                  <Grid item xs sx={{ overflow: "auto" }}>
                    <SelectList
                      multiSelect
                      items={selectedCenter?.rooms.map(
                        r => ({ ...r, inListType: RoomTypeId.SECONDARY } as RoomListItem)
                      )}
                      value={selectedCenter?.rooms
                        .filter(r => r.roomTypeId === RoomTypeId.SECONDARY)
                        .map<RoomListItem>(r => ({ ...r, inListType: RoomTypeId.SECONDARY }))}
                      disabled={!selectedCenter || loadingCenters}
                      isItemEqualToValue={(i1, i2) => i1.id === i2.id}
                      onChange={newValue => updateRoomSelection(newValue || [], RoomTypeId.SECONDARY)}
                      renderItem={RoomItem}
                      sx={{ minWidth: "15rem" }}
                    />
                  </Grid>
                </Grid>
                <Grid item container direction="column" xs>
                  <Grid item>
                    <AdminFormLabel
                      label={`Aged Consult Rooms (${
                        selectedCenter?.rooms.filter(r => r.roomTypeId === RoomTypeId.AGED).length || 0
                      })`}
                      readme={agedRoomReadme}
                    />
                  </Grid>
                  <Grid item xs sx={{ overflow: "auto" }}>
                    <SelectList
                      multiSelect
                      items={selectedCenter?.rooms.map(r => ({ ...r, inListType: RoomTypeId.AGED } as RoomListItem))}
                      value={selectedCenter?.rooms
                        .filter(r => r.roomTypeId === RoomTypeId.AGED)
                        .map<RoomListItem>(r => ({ ...r, inListType: RoomTypeId.AGED }))}
                      disabled={!selectedCenter || loadingCenters}
                      isItemEqualToValue={(i1, i2) => i1.id === i2.id}
                      onChange={newValue => updateRoomSelection(newValue || [], RoomTypeId.AGED)}
                      renderItem={RoomItem}
                      sx={{ minWidth: "15rem" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justifyContent="center" spacing={2}>
                {updateLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <Grid item>
                      <Button
                        color="secondary"
                        disabled={!selectedCenter}
                        startIcon={<CancelIcon />}
                        onClick={() => setSelectedCenter(c => c && { ...centers.find(c2 => c2.id === c.id)! })}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        startIcon={<CheckIcon />}
                        disabled={
                          !selectedCenter ||
                          JSON.stringify(centers?.find(c => c.id === selectedCenter.id)) ===
                            JSON.stringify(selectedCenter)
                        }
                        onClick={() =>
                          setUpdateRequest(
                            r =>
                              r && {
                                ...r,
                                url: `centers/${selectedCenter!.id}/holidays/${selectedCenter!.holiday.id}`,
                                payload: selectedCenter
                              }
                          )
                        }
                      >
                        Save Changes
                      </Button>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ToolWrapper>
  );
};

const holidayDateTemplate = (holiday: EnhancedHoliday) =>
  `${holiday.startDateTime?.toLocaleString(DateTime.DATE_MED)} - ${holiday.endDateTime?.toLocaleString(
    DateTime.DATE_MED
  )}`;
const HolidayOption: React.FC<{ props: HTMLAttributes<HTMLLIElement>; holiday: EnhancedHoliday }> = ({
  props,
  holiday
}) => (
  <li {...props}>
    <Grid container justifyContent="space-between">
      <Grid item>{holiday.name}</Grid>
      {holiday.id !== defaultHolidayId && <Grid item>{holidayDateTemplate(holiday)}</Grid>}
    </Grid>
  </li>
);

const CenterItem: React.FC<SelectListItemProps<EnhancedCenter>> = ({ item: center, ...rest }) => (
  <SelectListItem label={center => center.name} item={center} {...rest}>
    <ListItemIcon>
      {Center.isDisabled(center) ? (
        <Chip size="small" label="disabled" color="secondary" />
      ) : (
        <Chip size="small" label={`${(center.holiday.sensitivity?.slider.value || 0) * 100}%`} />
      )}
    </ListItemIcon>
  </SelectListItem>
);

const RoomItem: React.FC<SelectListItemProps<RoomListItem>> = ({ item: room, ...rest }) => (
  <SelectListItem
    label={room => room.name}
    item={room}
    {...rest}
    // disable items that are selected as another type
    disabled={room.roomTypeId && room.inListType !== room.roomTypeId}
  />
);

export default CenterSettings;
