import axios, { AxiosInstance } from "axios";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useRef } from "react";
import { UseApiConfig, UseApiRequest, UseApiResponse, useApi } from "sonobello.utilities.react.axios";

import AppContext from "../components/AppContext";
import { ApiError } from "../models/ApiError";

const axiosInstance: AxiosInstance = axios.create({ baseURL: process.env.REACT_APP_API_BASEURL });

export type UseDbCustom<T extends Record<string, unknown> = Record<string, unknown>> = { name: string } & T;

export type UseDbConfig = Omit<UseApiConfig, "axiosInstance">;
export interface UseDbRequest<TRequestPayload = undefined, TCustom = undefined>
  extends Omit<UseApiRequest<TRequestPayload, TCustom>, "custom"> {
  custom?: TCustom;
}
export interface UseDbProps<TRequestPayload = undefined, TCustom = undefined> {
  request?: UseDbRequest<TRequestPayload, TCustom>;
  config?: UseDbConfig;
}

/** An overloaded useApi hook which directly supplies the Axios instance to the Textel API backend and fires notifications on
 * failed or recovered requests.
 * @typeParam TResponsePayload - the type expected on the response payload, if any.
 * @typeParam TRequestPayload - the type expected on the request payload, if any.
 * @typeParam TCustom - the type expected on the custom props object, which will be merged with {@link UseDbCustom}.
 * @typeParam TResponseError - the type expected on an error response payload, if any.
 * @param name - convenience param for the name of the hook, maps to {@link UseDbCustom.name}.
 * @param request - see {@link UseApiProps.request} sans header auth params.
 * @param config - the initial {@link UseApiProps.config} sans {@link UseApiConfig.axiosInstance}.
 */
const useDb = <
  TResponsePayload = undefined,
  TRequestPayload = undefined,
  TCustom extends Record<string, unknown> = Record<string, unknown>,
  TResponseError extends ApiError = ApiError
>(
  name: string,
  request?: UseDbRequest<TRequestPayload, TCustom>,
  config?: UseDbConfig
): UseApiResponse<TRequestPayload, UseDbCustom<TCustom>, TResponseError, TResponsePayload> => {
  const { enqueueSnackbar } = useSnackbar();
  const { token, refreshToken } = useContext(AppContext);
  const hook = useApi<TResponsePayload, TRequestPayload, UseDbCustom<TCustom>, TResponseError>({
    request: {
      ...request,
      custom: { name, ...request?.custom } as UseDbCustom<TCustom>
    },
    config: { axiosInstance, token: token?.secret, isCancellable: false, ...config }
  });
  const responseRef = useRef(hook.res);
  const errorRef = useRef(hook.err);
  const tokenRef = useRef(token);

  // handle updating the authorization token
  useEffect(() => {
    let executeQuery = false;
    // if the token is defined, consider executing a query
    if (token) {
      // if token has been updated
      if (!tokenRef.current) {
        if (
          // execute if the previous request failed due to a bad token, or
          errorRef.current?.error?.response?.status === 401 ||
          // execute if the request has not fired yet
          (!errorRef.current && !responseRef.current)
        )
          executeQuery = true;
      }
    }
    hook.setConf((c: UseApiConfig) => ({ ...c, token: token?.secret }));
    if (executeQuery) hook.setReq(r => r && { ...r });
    tokenRef.current = token;
  }, [token]);

  responseRef.current = hook.res;

  // handle receiving or resolving error responses
  useEffect(() => {
    // if an error occurred
    if (hook.err) {
      if (hook.err?.error.response?.status === 401) refreshToken();
      else enqueueSnackbar(`${hook.err.request.custom.name} Failed`, { variant: "error" });
    }
    // else if resolving a previous failure
    else if (errorRef.current)
      enqueueSnackbar(`${errorRef.current.error.request.custom.name} Resolved`, { variant: "success" });
    errorRef.current = hook.err;
  }, [hook.err]);

  return hook;
};

export default useDb;
