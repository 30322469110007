import { AppBar, CircularProgress, Collapse, Grid, Toolbar, Typography } from "@mui/material";
import React, { useContext } from "react";

import AppContext from "./AppContext";

/** A contained banner with open/close animations and rendering its children on the notification bar. */
const LoadingRoomsBanner: React.FC = () => {
  const { roomRequests } = useContext(AppContext);
  const unfulfilledRequests = roomRequests.filter(r => !r.success);
  return (
    <Collapse in={Boolean(unfulfilledRequests.length)}>
      <AppBar position="static" color="secondary">
        <Toolbar sx={{ justifyContent: "center" }} variant="dense">
          <Grid container justifyContent="center" alignItems="center" columnSpacing={2} sx={{ p: 1 }}>
            <Grid item>
              <CircularProgress />
            </Grid>
            <Grid item>
              <Typography variant="body1">{`Loading rooms for ${unfulfilledRequests.length} new centers.`}</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </Collapse>
  );
};

export default LoadingRoomsBanner;
