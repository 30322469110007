import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Button,
  Chip,
  ChipTypeMap,
  CircularProgress,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  ListItemIcon,
  Switch,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  AdminFormLabel,
  Optional,
  SelectList,
  SelectListItem,
  SelectListItemProps,
  ValidatedTextField,
  ValidationButton
} from "sonobello.utilities.react.mui";

import { AnalyticsType, AnalyticsTypeName } from "../../models/AnalyticsTypes";
import { Category } from "../../models/Category";
import { EnhancedVariable, Variable } from "../../models/Variable";
import useDb from "../../utils/UseDb";
import { AdminTool } from "../Main";
import ToolWrapper from "../ToolWrapper";

const toolReadme = `
The variables tool allows the user to configure the influence that various factors have on a double-booking
determination.

The user can alter how much importance any individual variable has to the overall evaluation of an appointment.
`;
const variablesReadme = `
The configurable variables are listed here. If the variable is active, it's coefficient and category color are shown.

Variables that are disabled listed as such.
`;
const formReadme = `
Only variables that are enabled are taken into account during appointment evaluation.

The default value of a variable is the coefficient used when that information is not present on the appointment.

The overrides variable property setting causes the appointment evaluation to use the coefficient for the current
variable in place of the coefficient for the overridden variable if the current variable has a value on the
appointment.

The coefficient value is the coefficient used if the variable information is present on the appointment.
`;
const categoriesReadme = `
Categorical variables can establish categories where they apply specified coefficients when the variable value
matches the category name. If no category is met, the default value and coefficient value are used, as is normal
in other types of variables.
`;

const VariableSettings: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [variable, setVariable] = useState<EnhancedVariable>();
  const [variables, setVariables] = useState<EnhancedVariable[]>([]);
  const [queryCounter, setQueryCounter] = useState(-1);
  const [removedCategories, setRemovedCategories] = useState<Category[]>([]);

  // Get Models
  const { res: getAnalyticsTypes, loading: loadingAnalyticsTypes } = useDb<AnalyticsType[]>("Get Variables", {
    url: "analyticsTypes"
  });

  const { res: getVariables, loading: loadingVariables } = useDb<Variable[]>("Get Variables", { url: "variables" });
  const analyticsTypesRef = useRef(getAnalyticsTypes?.payload || []);
  useEffect(() => {
    if (getAnalyticsTypes?.payload.length) analyticsTypesRef.current = getAnalyticsTypes.payload;
  }, [getAnalyticsTypes]);
  useEffect(
    () =>
      getAnalyticsTypes &&
      getVariables &&
      setVariables(
        getVariables.payload.map(
          v =>
            new EnhancedVariable(
              v,
              getAnalyticsTypes.payload,
              v.overridesId ? getVariables.payload.find(v2 => v2.id === v.overridesId) : undefined
            )
        )
      ),
    [getVariables, getAnalyticsTypes]
  );

  // show spinner while loading
  useEffect(
    () => setIsContentLoading(loadingAnalyticsTypes || loadingVariables),
    [loadingAnalyticsTypes, loadingVariables]
  );

  // #region Update Variable Category
  const { res: postCategory, setReq: setPostCategoryReq } = useDb<Category, Category, { categoryName: string }>(
    "Update Category",
    { method: "post" }
  );
  const { res: updateVariable, setReq: setUpdateVarReq } = useDb<Variable, Variable, { categoryName: string }>(
    "Update Variable",
    { method: "put" }
  );
  useEffect(() => {
    if (!updateVariable) return;
    setQueryCounter(c => --c);
    setVariable(oldVariable => {
      if (!oldVariable) return;
      const newVariable = new EnhancedVariable(updateVariable.payload, analyticsTypesRef.current);
      // carry over new categories
      oldVariable.categories.forEach(c => {
        if (!newVariable.categories.some(c2 => c.id === c2.id && !isNaN(c.id))) newVariable.categories.push(c);
      });
      newVariable.categories.sort(Category.AlphaComparator);
      setVariables(vs => {
        vs[vs.findIndex(vsi => vsi.id === updateVariable.payload.id)] = { ...newVariable };
        return [...vs];
      });
      return newVariable;
    });
    enqueueSnackbar("Variable Updated", { variant: "success" });
  }, [updateVariable]);
  useEffect(() => {
    if (!postCategory) return;
    setQueryCounter(c => --c);
    // if we created a category, add its full value to the variable
    if (postCategory.payload)
      setVariable(v => {
        if (!v) return v;
        const catIndex = v?.categories.findIndex(c => c.name === postCategory.payload?.name);
        v.categories[catIndex] = postCategory.payload;
        return { ...v, categories: [...v.categories] };
      });
  }, [postCategory]);
  useEffect(() => {
    // only PUT the variable if all prior queries have resolved
    if (queryCounter != 0 || !variable) return;
    setUpdateVarReq(r => r && { ...r, url: `variables/${variable.id}`, payload: variable });
  }, [queryCounter, variable]);
  // #endregion

  // Actions
  const onSave = () => {
    if (!variable) return;
    let updateCounter = removedCategories.length;
    const categoryCreations = variable.categories.filter(c => isNaN(c.id));
    updateCounter += categoryCreations?.length;

    // if there are no category changes only consider saving the variable
    if (updateCounter == 0) {
      if (Variable.isChange(variable, variables.find(v => v.id === variable.id)!)) setQueryCounter(0);
      return;
    } else setQueryCounter(updateCounter);

    // otherwise batch the category creation and deletion queries
    let categoryQueryTime = 0;
    categoryCreations.forEach(payload => {
      const query = () =>
        setPostCategoryReq(r => r && { ...r, method: "post", url: `variables/${variable.id}/categories`, payload });
      if (!categoryQueryTime) query();
      else setTimeout(query, categoryQueryTime);
      categoryQueryTime += 500;
    });
    removedCategories.forEach(removeCategory => {
      const query = () =>
        setPostCategoryReq(
          r => r && { ...r, method: "delete", url: `variables/${variable.id}/categories/${removeCategory.id}` }
        );
      if (!categoryQueryTime) query();
      else setTimeout(query, categoryQueryTime);
      categoryQueryTime += 500;
    });
  };
  const onReset = () => {
    setVariable({ ...variables.find(v => v.id === variable?.id)! });
    setRemovedCategories([]);
  };

  return (
    <ToolWrapper title="Center Settings" readme={toolReadme} sx={{ minWidth: "60rem" }}>
      <Grid container direction="column" alignItems="center" columnSpacing={2} sx={{ height: "100%" }}>
        <Grid container item xs spacing={2} wrap="nowrap" sx={{ overflow: "hidden" }}>
          <Grid item xs>
            <Grid container direction="column" spacing={2} wrap="nowrap" sx={{ height: "100%" }}>
              <Grid item>
                <AdminFormLabel label="Variables" readme={variablesReadme} />
              </Grid>
              <Grid item xs sx={{ overflow: "auto" }}>
                <SelectList
                  items={variables}
                  value={variable || null}
                  disabled={loadingVariables}
                  isItemEqualToValue={(i1, i2) => i1.id === i2.id}
                  onChange={newValue => setVariable(newValue || undefined)}
                  renderItem={VariableItem}
                  sx={{ minWidth: "20rem" }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs>
            <Grid container direction="column" spacing={2} sx={{ height: "100%", overflow: "auto" }} wrap="nowrap">
              <Grid item>
                <AdminFormLabel
                  label={`Configuration ${variable ? ` for ${variable.name}` : ""}`}
                  readme={formReadme}
                />
              </Grid>
              <Grid item>
                <Typography variant="h6" component="span">
                  Type:
                </Typography>
                <Typography variant="body1" component="span">
                  {` ${variable?.analyticsType.name || ""}`}
                </Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<Switch />}
                  disabled={!variable}
                  checked={variable?.active || false}
                  onChange={(_, active) => setVariable(v => v && { ...v, active })}
                  label="Enabled"
                />
              </Grid>
              <Grid item>
                <TextField label="Default Value" value={variable?.defaultValue || ""} />
              </Grid>
              <Grid item>
                <Autocomplete
                  disabled={!variable}
                  options={getVariables?.payload.filter(v => v.id !== variable?.id) || []}
                  renderInput={props => <TextField {...props} label="Overrides Variable" />}
                  getOptionLabel={v => v.name}
                  value={variable?.overrideVariable || null}
                  isOptionEqualToValue={(v1, v2) => v1.id === v2.id}
                  onChange={(_, newVar) =>
                    setVariable(v => v && { ...v, overridesId: newVar?.id, overrideVariable: newVar || undefined })
                  }
                />
              </Grid>
              {variable?.analyticsType.name === AnalyticsTypeName.Continuous ? (
                <Grid item>
                  <ValidatedTextField
                    label="Coefficient"
                    value={variable?.coefficient || ""}
                    onAccepted={value => setVariable(v => v && { ...v, coefficient: value ? Number(value) : 0 })}
                  />
                </Grid>
              ) : variable?.analyticsType.name === AnalyticsTypeName.Categorical ? (
                <Grid item>
                  <CategoricalForm
                    variable={variable}
                    setVariable={setVariable}
                    setRemovedCategories={setRemovedCategories}
                  />
                </Grid>
              ) : undefined}
              <Grid item>
                <Grid container justifyContent="center" columnSpacing={2}>
                  {queryCounter >= 0 ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <Grid item>
                        <Button startIcon={<CancelIcon />} onClick={onReset} color="secondary">
                          Reset
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button disabled={!variable} onClick={onSave}>
                          Save Changes
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ToolWrapper>
  );
};

interface EditFormProps {
  variable: EnhancedVariable;
  setVariable: Dispatch<SetStateAction<Optional<EnhancedVariable>>>;
  setRemovedCategories: Dispatch<SetStateAction<Category[]>>;
}

const CategoricalForm: React.FC<EditFormProps> = ({ variable, setVariable, setRemovedCategories }) => {
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [coefficient, setCoefficient] = useState(String(selectedCategory?.coefficient || 0));
  const onSelected = (c?: Category) => {
    setSelectedCategory(c);
    if (c) setCoefficient((c.coefficient || 0).toString());
  };
  const onAdd = () => {
    setSelectedCategory({ name: "", coefficient: 0, id: -1, analyticsVariableId: variable.id });
    setCoefficient("0");
  };
  const onConfirm = () => {
    setSelectedCategory(c => {
      if (!c) return c;
      setVariable(v => {
        if (!v) return v;
        let index = v.categories.findIndex(vc => vc.id === c.id);
        if (index === -1) index = v.categories.findIndex(vc => vc.name === c.name);
        if (index === -1)
          return { ...v, categories: [...v.categories, { ...c, id: NaN, analyticsVariableId: variable.id }] };
        v.categories[index] = c;
        return { ...v, categories: [...v.categories] };
      });
      return undefined;
    });
  };
  const onDelete = (category: Category) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setSelectedCategory(undefined);
    setVariable(v => {
      if (!v) return v;
      const index = v.categories.findIndex(cat => cat.id === category.id);
      const removedCategory = v.categories.splice(index, 1)[0];
      // if the category has an id, queue it for removal
      if (removedCategory.id >= 0) setRemovedCategories(c => [...c, removedCategory]);
      return { ...v, categories: [...v.categories] };
    });
  };
  return (
    <Grid container direction="column" spacing={2} alignItems="center">
      <Grid container item>
        <AdminFormLabel label="Categories" readme={categoriesReadme} />
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <SelectList
          renderItem={CategoryItem}
          items={variable.categories.map(c => ({ ...c, onDelete: onDelete(c) }))}
          onChange={c => onSelected(c)}
          value={selectedCategory}
          isItemEqualToValue={(v1, v2) => v1.name === v2.name}
        />
      </Grid>
      <Grid item>
        <Collapse in={!selectedCategory}>
          <Button onClick={onAdd} startIcon={<AddCircleIcon />}>
            Add Category
          </Button>
        </Collapse>
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Collapse in={Boolean(selectedCategory)}>
          <Grid container direction="column" spacing={2} wrap="nowrap">
            <Grid item container spacing={2} alignItems="center" sx={{ width: "100%" }}>
              <Grid item xs>
                <TextField
                  fullWidth
                  label="Name"
                  value={selectedCategory?.name || ""}
                  onChange={e => setSelectedCategory(c => c && { ...c, name: e.target.value || "" })}
                />
              </Grid>
              <Grid item xs>
                <ValidatedTextField
                  fullWidth
                  label="Coefficient"
                  value={coefficient}
                  onChange={v => setCoefficient(v || "")}
                  onAccepted={v => setSelectedCategory(c => c && { ...c, coefficient: Number(v) })}
                  rejectInput={value => !/^([0-1]{0,1}\.?[0-9]{0,2})$/.test(value)}
                  tests={[{ test: value => Number(value) < 1, message: "The value cannot be greater than 1." }]}
                  helperText={null}
                />
              </Grid>
            </Grid>
            <Grid item container justifyContent="center" spacing={2}>
              <Grid item>
                <Button color="secondary" startIcon={<CancelIcon />} onClick={() => setSelectedCategory(undefined)}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <ValidationButton
                  startIcon={<CheckIcon />}
                  onClick={onConfirm}
                  tests={[
                    c => !c!.name && "Name is required",
                    c => (c!.coefficient > 1 ? "Coefficient cannot be greater than 1." : undefined),
                    c => (c!.coefficient < 0 ? "Coefficient cannot be less than 0." : undefined)
                  ]}
                  validationTarget={selectedCategory}
                >
                  Confirm
                </ValidationButton>
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
      <Grid item></Grid>
      <Grid item></Grid>
    </Grid>
  );
};

// Map each analytics type to an allowed chip color
const analyticsTypeColorMap: Record<AnalyticsTypeName, ChipTypeMap["props"]["color"]> = {
  [AnalyticsTypeName.Categorical]: "info",
  [AnalyticsTypeName.Continuous]: "success",
  [AnalyticsTypeName.Intercept]: "warning"
};

const VariableItem: React.FC<SelectListItemProps<EnhancedVariable>> = ({ item: variable, ...rest }) => (
  <SelectListItem label={variable => variable.name} item={variable} {...rest}>
    <ListItemIcon>
      <Chip
        size="small"
        color={variable.active ? analyticsTypeColorMap[variable.analyticsType.name] : "secondary"}
        label={variable.active ? variable.coefficient : "disabled"}
        sx={{ ml: 2 }}
      />
    </ListItemIcon>
  </SelectListItem>
);

interface CategoryListItem extends Category {
  onDelete: React.MouseEventHandler<HTMLButtonElement>;
}
const CategoryItem: React.FC<SelectListItemProps<CategoryListItem>> = ({ item: category, ...rest }) => (
  <SelectListItem label={c => c.name} item={category} {...rest}>
    <ListItemIcon>
      <Chip size="small" label={category.coefficient} />
    </ListItemIcon>
    <Tooltip title="Delete">
      <IconButton onClick={category.onDelete}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  </SelectListItem>
);

export default VariableSettings;
