import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import {
  Alert,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  Slider as MuiSlider,
  Popover,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import { AdminFormLabel, ValidatedTextField, ValidationButton } from "sonobello.utilities.react.mui";

import { Slider } from "../../models/Slider";
import { SliderConfig } from "../../types/SliderConfig";
import useDb from "../../utils/UseDb";
import { AdminTool } from "../Main";
import ToolWrapper from "../ToolWrapper";

const toolReadme = `
This tool allows the user to set the demarcations on the sensitivity slider. You cannot add or remove these marks, only modify the provided ones.

**When you modify this value, all center settings that are currently using that sensitivity value will use the new value.**

For example, if Center A is set to a sensitivity of 50%, and this tool modifies that value to become 55%, then the new
effective sensitivity of Center A will become 55%.
`;

const positionsReadme = `
You may only set positions between \`.01\` and \`.99\`. The minimum and maximum values on the slider will always be \`0\` and \`100\`.

You may not set two slider positions to the same value.
`;

const defaultHolidayId = Number(process.env.REACT_APP_Default_HOLIDAY_ID);
if (isNaN(defaultHolidayId)) throw "Configuration for the default holiday id is missing or default.";

const SliderSettings: React.FC<AdminTool> = ({ setIsContentLoading }: AdminTool) => {
  const { enqueueSnackbar } = useSnackbar();
  const [update, setUpdate] = useState<string[]>([]);
  const [preview, setPreview] = useState<{ config?: SliderConfig; disabled: boolean }>({ disabled: true });
  const [sliderConfig, setSliderConfig] = useState<SliderConfig>();
  const [confirmAnchor, setConfirmAnchor] = useState<HTMLElement>();
  const [queryCounter, setQueryCounter] = useState(0);
  const queryCounterRef = useRef(queryCounter);

  const { res: getSliders, loading: loadingSliders } = useDb<Slider[]>("Get Sliders", { url: "slider" });

  useEffect(() => {
    if (!getSliders?.payload.length) return;
    setSliderConfig(new SliderConfig(getSliders.payload));
    setUpdate(getSliders.payload.map(s => String(s.value)));
  }, [getSliders]);

  // show spinner while loading
  useEffect(() => setIsContentLoading(loadingSliders), [loadingSliders]);

  // #region Update Sliders
  const {
    res: updateSliders,
    loading: updateLoading,
    setReq: setUpdateRequest
  } = useDb<Slider, Slider>("Update Slider", { method: "put" });
  useEffect(() => {
    if (!updateSliders) return;
    setQueryCounter(v => --v);
    if (--queryCounterRef.current) return;
    enqueueSnackbar("Sliders Updated", { variant: "success" });
    setPreview(oldPreview => {
      setSliderConfig(oldPreview?.config);
      return { disabled: true };
    });
  }, [updateSliders]);
  // #endregion

  // Actions
  const onConfirm = () => {
    setConfirmAnchor(undefined);
    const changes = preview?.config?.sliders.reduce((collector, slider) => {
      const oldSlider = sliderConfig?.sliders.find(s => s.id === slider.id);
      if (!oldSlider) throw "slider not found";
      if (oldSlider.value !== slider.value) collector.push(slider);
      return collector;
    }, [] as Slider[]);
    if (!changes?.length) return;
    queryCounterRef.current = changes.length;
    setQueryCounter(changes.length);
    let queryTimer = 0;
    changes.forEach(change => {
      const queryFunc = () => setUpdateRequest(r => r && { ...r, url: `slider/${change.id}`, payload: change });
      if (!queryTimer) queryFunc();
      else setTimeout(queryFunc, queryTimer);
      queryTimer += 500;
    });
  };
  const onPositionChange = (index: number) => (value?: string) =>
    setUpdate(u => {
      u[index] = value || "";
      const parsedValues = u.map(v => Number(v));
      if (parsedValues.some((v, i) => isNaN(v) || parsedValues.some((v2, i2) => v === v2 && i !== i2)))
        setPreview(p => ({ ...p, disabled: true }));
      else setPreview({ config: SliderConfig.fromValues(parsedValues, sliderConfig!), disabled: false });
      return [...u];
    });
  const onCancel = () => {
    setUpdate(sliderConfig!.marks.map(m => String(m.value)));
    setPreview({ disabled: true });
  };

  return (
    <ToolWrapper title="Slider Settings" readme={toolReadme} sx={{ minWidth: "55rem", height: undefined }}>
      <Grid container direction="column" alignItems="center" rowSpacing={3} sx={{ width: "100%" }}>
        <Grid item sx={{ width: "100%" }}>
          <Alert color="warning">WARNING: Changing these settings will affect global sensitivities.</Alert>
        </Grid>
        <Grid item sx={{ width: "100%", px: 2 }}>
          {sliderConfig && (
            <MuiSlider step={null} min={sliderConfig.min} max={sliderConfig.max} marks={sliderConfig.marks} />
          )}
        </Grid>
        <Grid item>
          <AdminFormLabel label="Slider Positions" readme={positionsReadme} />
        </Grid>
        <Grid item container justifyContent="space-between">
          {update.map(
            (s, index) =>
              index !== 0 &&
              index !== update.length - 1 && (
                <Grid item key={index}>
                  <ValidatedTextField
                    value={s}
                    label={`Position ${index}`}
                    sx={{ maxWidth: "6rem" }}
                    onChange={onPositionChange(index)}
                    rejectInput={value => !/^([0]{0,1}\.?[0-9]{0,2})$/.test(value)}
                  />
                </Grid>
              )
          )}
        </Grid>
        <Collapse in={Boolean(preview.config)} sx={{ width: "100%" }}>
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6" align="center">
                  Change Preview:
                </Typography>
              </Grid>
              <Grid item sx={{ width: "100%", px: 2 }}>
                {preview.config && (
                  <MuiSlider
                    disabled={preview.disabled}
                    step={null}
                    min={preview.config.min}
                    max={preview.config.max}
                    marks={preview.config.marks}
                    color="secondary"
                  />
                )}
              </Grid>
              <Grid item container justifyContent="center" spacing={3}>
                {updateLoading || queryCounter ? (
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                ) : (
                  <>
                    <Grid item>
                      <Button startIcon={<CancelIcon />} color="secondary" onClick={onCancel}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <ValidationButton
                        disabled={preview.disabled}
                        validationTarget={sliderConfig?.sliders}
                        tests={[]}
                        startIcon={<CheckIcon />}
                        onClick={e => setConfirmAnchor(e.currentTarget)}
                      >
                        Save Changes
                      </ValidationButton>
                      <Popover
                        open={Boolean(confirmAnchor)}
                        anchorEl={confirmAnchor}
                        onClose={() => setConfirmAnchor(undefined)}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        transformOrigin={{ vertical: "top", horizontal: "center" }}
                      >
                        <Grid container direction="column" alignItems="center" spacing={2} sx={{ p: 2 }}>
                          <Grid item>
                            <Typography variant="h6">{`Are you sure? This will affect all sensitivities.`}</Typography>
                          </Grid>
                          <Grid item>
                            <Button onClick={onConfirm}>Confirm</Button>
                          </Grid>
                        </Grid>
                      </Popover>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </ToolWrapper>
  );
};

export default SliderSettings;
