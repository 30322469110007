import { DateTime } from "luxon";
import { ComparatorFn } from "sonobello.utilities.react.mui";

import { EnhancedSensitivity, Sensitivity } from "./Sensitivity";
import { Slider } from "./Slider";

/** A holiday, allowing for a linked time-specific center sensitivity. */
export class Holiday {
  /** The unique identifier of the holiday. */
  id!: number;
  /** The plain text name of the holiday. */
  name!: string;
  /** The ISO date on which the holiday starts. */
  startDate?: string;
  /** The ISO date on which the holiday ends. */
  endDate?: string;
  /** The associated {@link Sensitivity} of the holiday. */
  sensitivity?: Sensitivity;
}

/** The abridged post request format for creating a new holiday. */
export type PostHolidayRequest = Pick<Holiday, "name" | "startDate" | "endDate">;

/** An extension of the holiday model with additional date time and linked object props. */
export class EnhancedHoliday extends Holiday {
  /** The luxon dateTime for the {@link Holiday.startDate}. */
  startDateTime?: DateTime;
  /** The luxon dateTime for the {@link Holiday.endDate}. */
  endDateTime?: DateTime;
  /** The enhanced sensitivity object. */
  sensitivity?: EnhancedSensitivity;

  constructor(holiday: Holiday, slider?: Slider) {
    super();
    this.id = holiday.id;
    this.name = holiday.name;
    this.sensitivity = holiday.sensitivity && slider && { ...holiday.sensitivity, slider };
    this.startDateTime = holiday.startDate ? DateTime.fromISO(holiday.startDate) : undefined;
    this.endDateTime = holiday.startDate ? DateTime.fromISO(holiday.startDate) : undefined;
  }

  /** Comparator which sorts holidays into chronological order. */
  static chronoComparator: ComparatorFn<EnhancedHoliday> = (h1, h2) => {
    if (!h1.startDateTime) return 1;
    if (!h2.startDateTime) return -1;
    if (h1.startDateTime > h2.startDateTime) return 1;
    return -1;
  };

  /** Converts an enhanced holiday back to the base. */
  static toBase(holiday: EnhancedHoliday): Holiday {
    const { id, name, sensitivity, startDate, endDate } = holiday;
    return { id, name, sensitivity, startDate, endDate };
  }
}
